@font-face {
  font-family: 'SFProDisplay';
  src: url('UI/fonts/SF-Pro-Display-Thin.woff2') format('woff2'),
    url('UI/fonts/SF-Pro-Display-Thin.woff') format('woff'),
    url('UI/fonts/SF-Pro-Display-Thin.ttf') format('truetype');
  font-weight: 100;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('UI/fonts/SF-Pro-Display-ThinItalic.woff2') format('woff2'),
    url('UI/fonts/SF-Pro-Display-ThinItalic.woff') format('woff'),
    url('UI/fonts/SF-Pro-Display-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('UI/fonts/SF-Pro-Display-Ultralight.woff2') format('woff2'),
    url('UI/fonts/SF-Pro-Display-Ultralight.woff') format('woff'),
    url('UI/fonts/SF-Pro-Display-Ultralight.ttf') format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('UI/fonts/SF-Pro-Display-UltralightItalic.woff2') format('woff2'),
    url('UI/fonts/SF-Pro-Display-UltralightItalic.woff') format('woff'),
    url('UI/fonts/SF-Pro-Display-UltralightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('UI/fonts/SF-Pro-Display-Light.woff2') format('woff2'),
    url('UI/fonts/SF-Pro-Display-Light.woff') format('woff'),
    url('UI/fonts/SF-Pro-Display-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('UI/fonts/SF-Pro-Display-LightItalic.woff2') format('woff2'),
    url('UI/fonts/SF-Pro-Display-LightItalic.woff') format('woff'),
    url('UI/fonts/SF-Pro-Display-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('UI/fonts/SF-Pro-Display-Regular.woff2') format('woff2'),
    url('UI/fonts/SF-Pro-Display-Regular.woff') format('woff'),
    url('UI/fonts/SF-Pro-Display-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('UI/fonts/SF-Pro-Display-RegularItalic.woff2') format('woff2'),
    url('UI/fonts/SF-Pro-Display-RegularItalic.woff') format('woff'),
    url('UI/fonts/SF-Pro-Display-RegularItalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('UI/fonts/SF-Pro-Display-Medium.woff2') format('woff2'),
    url('UI/fonts/SF-Pro-Display-Medium.woff') format('woff'),
    url('UI/fonts/SF-Pro-Display-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('UI/fonts/SF-Pro-Display-MediumItalic.woff2') format('woff2'),
    url('UI/fonts/SF-Pro-Display-MediumItalic.woff') format('woff'),
    url('UI/fonts/SF-Pro-Display-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('UI/fonts/SF-Pro-Display-Semibold.woff2') format('woff2'),
    url('UI/fonts/SF-Pro-Display-Semibold.woff') format('woff'),
    url('UI/fonts/SF-Pro-Display-Semibold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('UI/fonts/SF-Pro-Display-SemiboldItalic.woff2') format('woff2'),
    url('UI/fonts/SF-Pro-Display-SemiboldItalic.woff') format('woff'),
    url('UI/fonts/SF-Pro-Display-SemiboldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('UI/fonts/SF-Pro-Display-Bold.woff2') format('woff2'),
    url('UI/fonts/SF-Pro-Display-Bold.woff') format('woff'),
    url('UI/fonts/SF-Pro-Display-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('UI/fonts/SF-Pro-Display-BoldItalic.woff2') format('woff2'),
    url('UI/fonts/SF-Pro-Display-BoldItalic.woff') format('woff'),
    url('UI/fonts/SF-Pro-Display-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('UI/fonts/SF-Pro-Display-Heavy.woff2') format('woff2'),
    url('UI/fonts/SF-Pro-Display-Heavy.woff') format('woff'),
    url('UI/fonts/SF-Pro-Display-Heavy.ttf') format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('UI/fonts/SF-Pro-Display-HeavyItalic.woff2') format('woff2'),
    url('UI/fonts/SF-Pro-Display-HeavyItalic.woff') format('woff'),
    url('UI/fonts/SF-Pro-Display-HeavyItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('UI/fonts/SF-Pro-Display-Black.woff2') format('woff2'),
    url('UI/fonts/SF-Pro-Display-Black.woff') format('woff'),
    url('UI/fonts/SF-Pro-Display-Black.ttf') format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('UI/fonts/SF-Pro-Display-BlackItalic.woff2') format('woff2'),
    url('UI/fonts/SF-Pro-Display-BlackItalic.woff') format('woff'),
    url('UI/fonts/SF-Pro-Display-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

